import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo"/>

                <a
                    className="App-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="http://www.beian.miit.gov.cn/">
                    京ICP备15050396号-1</a>
            </header>
        </div>
    );
}

export default App;
